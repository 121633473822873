import { FixMeLater } from '../../../../../types/FixMeLaterType';
import { GenericDictionary } from '../../../../../types/GenericDictionary';

export interface ProgrammeContact {
  email: string;
  name: string;
  title: string;
}

export interface ActivityChoice {
  choiceId: string;
}

export interface ProgrammeRequirement {
  key?: string; // Used here for creating a new requirement, not required when fetching from the API
  activities: {
    name: string,
    activityId: string,
    choiceIds: ActivityChoice[]
  }[],
  answer: string;
  code: string;
  description: string;
  question: string;
  type: string;
}


export interface MgList extends GenericDictionary<MgPage> {}

export interface MgPage {
  materials?: GenericDictionary<MaterialGroup>;
  minVariants?: number;
  title: string;
}

export interface MaterialGroup {
  materialNumbers?: string[];
  title: string;
}

export interface MgOption {
  code: string;
  isActive: boolean;
  value: string;
}

export interface MaterialOption {
  Description: string;
  Number: boolean;
  value: string;
}

export interface ProgrammePdf {
  dateAdded: string;
  name: string;
  url: string;
}

export interface ProgrammePriceBook {
  excludeFromProgramme: boolean;
}

export interface SamaProgramme {
  id: string;
  active: boolean;
  bolton?: boolean;
  prerequisite: string | null;
  availableToSubTrades: string[];
  bannerImage: string;
  businessOwner: string;
  contacts: ProgrammeContact[];
  alertEmailAddresses: string[];
  createdAt: string;
  createdBy: string;
  description: string;
  details: {
    compliance: {
      priceChangeNotice: string;
      redScore: number | null;
      requirements: GenericDictionary<ProgrammeRequirement> | null;
    }
    general: {
      length: number;
      name: string;
    }
  }
  detailsToCapture: string[];
  minimumProductGroups: number;
  pdfs: ProgrammePdf[];
  priceBooks: GenericDictionary<ProgrammePriceBook>;
  productGroups: GenericDictionary<MgPage>;
  protected: boolean;
}

export interface ProgrammeAction {
  type: string;
  payload: any;
}

export const initialFormState: SamaProgramme = {
  id: '',
  active: false,
  bolton: false,
  prerequisite: null,
  availableToSubTrades: [],
  bannerImage: '',
  businessOwner: '',
  contacts: [],
  alertEmailAddresses: [],
  createdAt: '',
  createdBy: '',
  description: '',
  details: {
    compliance: {
      priceChangeNotice: '',
      redScore: null,
      requirements: null,
    },
    general: {
      length: 0,
      name: ''
    },
  },
  detailsToCapture: [],
  minimumProductGroups: -1,
  pdfs: [],
  priceBooks: {},
  productGroups: {},
  protected: false
}

function parseNumericeValue(value: string | null | undefined): number | null {
  const val  = value === ''
  || value === undefined
  || value === null
   ? null : parseInt(value, 10);

   return val;
}

export const programmeReducer = (state: SamaProgramme, action: ProgrammeAction) => {
  switch (action.type) {
    case "RESET": {
      return {
        ...action.payload,
      };
    }
    case "SET_VALUE": {
      return {
        ...state,
        [action.payload.name]: action.payload.value
      };
    }
    case "SET_NUMERIC_VALUE": {
      return {
        ...state,
        [action.payload.name]: parseNumericeValue(action.payload.value)
      };
    }
    case "SET_CHECKBOX_VALUE": {
      return {
        ...state,
        [action.payload.name]: action.payload.checked
      };
    }
    case "SET_PRICE_CHANGE_NOTICE_VALUE": {
      let newState = { ...state };
      newState.details.compliance.priceChangeNotice = action.payload.value;
      return newState;
    }
    case "SET_AVAILABLE_TO_SUB_TRADES": {
      let newState = { ...state };
      const value = action.payload.value.replace(' ','').split(',');
      if(value === '') {
        newState.availableToSubTrades = [];
        return newState;
      };

      newState.availableToSubTrades = action.payload.value.replace(' ','').split(',');
      return newState;
    }
    case "SET_DETAILS_TO_CAPTURE": {
      let newState = { ...state };
      const value = action.payload.value.replace(' ', '').split(',');
      newState.detailsToCapture = value === '' ? [] : value;
      return newState;
    }
    case "SET_RED_SCORE_VALUE": {
      let newState = { ...state };
      newState.details.compliance.redScore = parseNumericeValue(action.payload.value);
      return newState;
    }
    case "ADD_CONTACT": {
      let newState = { ...state };
      newState.contacts.push(action.payload.value);
      return newState;
    }
    case "REMOVE_CONTACT": {
      let newState = { ...state };
      newState.contacts.splice(action.payload.index, 1);
      return newState;
    }
    case "ADD_ALERT_EMAIL": {
      let newState = { ...state };
      if(!newState.alertEmailAddresses) newState.alertEmailAddresses = [];
      newState.alertEmailAddresses.push(action.payload.value);
      return newState;
    }
    case "REMOVE_ALERT_EMAIL": {
      let newState = { ...state };
      newState.alertEmailAddresses.splice(action.payload.index, 1);
      return newState;
    }
    case "SET_NAME": {
      let newState = { ...state };
      newState.details.general.name = action.payload.value;
      return newState;
    }
    case "SET_LENGTH": {
      let newState = { ...state };
      newState.details.general.length = parseNumericeValue(action.payload.value) || 0;
      return newState;
    }
    case "ADD_REQUIREMENT": {
      let newState = { ...state };
      if(newState.details.compliance.requirements === null) {
        newState.details.compliance.requirements = {};
      }
      const reqKey = Object.keys(action.payload)[0];
      const reqValues = Object.values(action.payload)[0];
      newState.details.compliance.requirements[reqKey] = reqValues as ProgrammeRequirement;
      
      return newState;
    }
    case "UPDATE_REQUIREMENT": {
      let newState = { ...state };
      delete newState.details.compliance.requirements![action.payload.oldKey];
      newState.details.compliance.requirements![action.payload.newKey] = action.payload.value;
      return newState;
    }
    case "REMOVE_REQUIREMENT": {
      let newState = { ...state };
      if(newState.details.compliance.requirements){
        delete newState.details.compliance.requirements[action.payload.key];
      }
      return newState;
    }
    case "ADD_PDF": {
      let newState = { ...state };
      newState.pdfs.push(action.payload.value);
      return newState;
    }
    case "REMOVE_PDF": {
      let newState = { ...state };
      newState.pdfs.splice(action.payload.value, 1);
      return newState;
    }
    case "ADD_PRICE_BOOK": {
      let newState = { ...state };
      newState.priceBooks[action.payload.value] = { excludeFromProgramme: true };
      return newState;
    }
    case "REMOVE_PRICE_BOOK": {
      let newState = { ...state };
      delete newState.priceBooks[action.payload.value];
      return newState;
    }
    case "SET_NAME_VALUE": {
      let newState = { ...state };
      newState.details.general.name = action.payload.value;
      return newState;
    }
    case "SET_MATERIALS": {
      let newState = { ...state };
      newState.productGroups = action.payload;
      return newState;
    }
    default:
      return state;
  }
}